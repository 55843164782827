import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

/* eslint-disable */

const routes = [
    {
        path: "/",
        name: "Etel",
        component: () => import("../pages/Etel.vue"),
    },
    {
        path: "/csapolt_sorok",
        name: "Csapolt sörök",
        component: () => import("../pages/Csapolt_sorok.vue"),
    },
    {
        path: "/uveges_sorok",
        name: "Üveges sörök",
        component: () => import("../pages/Uveges_sorok.vue"),
    },
    {
        path: "/feher",
        name: "Fehér borok",
        component: () => import("../pages/FeherBorok.vue"),
    },
    {
        path: "/rose",
        name: "Rosé borok",
        component: () => import("../pages/Rose.vue"),
    },
    {
        path: "/voros",
        name: "Vörös borok",
        component: () => import("../pages/Voros.vue"),
    },
    {
        path: "/pezsgok",
        name: "Pezsgők",
        component: () => import("../pages/Pezsgok.vue"),
    },
    {
        path: "/froccsok",
        name: "Fröccsök",
        component: () => import("../pages/Froccsok.vue"),
    },
    {
        path: "/cider",
        name: "Cider",
        component: () => import("../pages/Cider.vue"),
    },
    {
        path: "/koktelok",
        name: "Koktélok",
        component: () => import("../pages/Koktelok.vue"),
    },
    {
        path: "/vodka",
        name: "Vodka",
        component: () => import("../pages/Vodka.vue"),
    },
    {
        path: "/whiskey",
        name: "Whiskey",
        component: () => import("../pages/Whiskey.vue"),
    },
    {
        path: "/palinka",
        name: "Pálinka",
        component: () => import("../pages/Palinka.vue"),
    },
    {
        path: "/gin",
        name: "Gin",
        component: () => import("../pages/Gin.vue"),
    },
    {
        path: "/rum",
        name: "Rum",
        component: () => import("../pages/Rum.vue"),
    },
    {
        path: "/keseru",
        name: "Keserű",
        component: () => import("../pages/KeseruLikor.vue"),
    },
    {
        path: "/likor",
        name: "Likőr",
        component: () => import("../pages/Likor.vue"),
    },
    {
        path: "/tequila",
        name: "Tequila",
        component: () => import("../pages/Tequila.vue"),
    },
    {
        path: "/limonadek",
        name: "Limonádék",
        component: () => import("../pages/Limonadek.vue"),
    },
    {
        path: "/szensavasUditok",
        name: "Szénsavas üdítők",
        component: () => import("../pages/SzensavasUditok.vue"),
    },
    {
        path: "/gyumolcslevek",
        name: "Gyümölcslevek",
        component: () => import("../pages/Gyumolcslevek.vue"),
    },
    {
        path: "/jegestea",
        name: "Jeges Tea",
        component: () => import("../pages/JegesTea.vue"),
    },
    {
        path: "/asvanyviz",
        name: "Ásványvíz",
        component: () => import("../pages/Asvanyviz.vue"),
    },
    {
        path: "/energiaitalok",
        name: "Energiaitalok",
        component: () => import("../pages/Energiaitalok.vue"),
    },
    {
        path: "/kave-tea",
        name: "Kávé Tea",
        component: () => import("../pages/KaveTea.vue"),
    },
    {
        path: "/ragcsa",
        name: "Rágcsálnivalók",
        component: () => import("../pages/Ragcsalnivalok.vue"),
    },
    {
        path: "/evi_es_fefe",
        name: "EviAndFefe",
        component: () => import("../pages/EviAndFefe.vue"),
    },
];

const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});


export default router;
