<template>
  <div class="goback">
    <b-button size="sm" @click="goUp"
      ><b-icon-arrow-up-square></b-icon-arrow-up-square> Lap tetejére</b-button
    >
    <span class="span"> </span>
    <b-button size="sm" @click="goBack"
      ><b-icon-arrow-left-square></b-icon-arrow-left-square> Vissza</b-button
    >
  </div>
</template>

<script>
export default {
  methods: {
    goUp() {
      window.scrollTo(0, 0);
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.goback {
  text-align: right;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
}
.span {
  margin-right: 2px;
  margin-left: 2px;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active {
  background-color: rgb(88, 73, 130);
}
</style>
