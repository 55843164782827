<template>
  <div id="app">
    <the-header></the-header>

    <transition name="route" mode="out-in">
      <router-view></router-view>
    </transition>
    <modal v-show="isModalVisible" @close="closeModal">
      <template v-slot:header> Kedves vendégeink! </template>
      <template v-slot:body>
        Szúnyogirtó spray soron kívül kérhető a pultban.
      </template>
    </modal>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";
import modal from "./components/Modal.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    modal,
  },
  mounted() {
    this.showModal();
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
#app {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.title {
  color: rgb(88, 73, 130);
  font-weight: 600;
}

.spinner {
  padding-top: 20px;
  padding-bottom: 100px;
}

.modal-body {
  padding: 0 !important;
}

.modal-footer {
  display: none !important;
}

.close {
  border-radius: 5px;
  border: 1px;
}

.modal-title {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
</style>
