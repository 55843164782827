<template>
  <div class="card-all">
    <b-card :title="title + ' - ' + price + ' Ft.'">
      <b-row>
        <b-col cols="8">
          <b-card-text>{{ description }}</b-card-text>
        </b-col>
        <b-col cols="4" class="image-col">
          <div @click="showImage(image_url, title)">
            <b-card-img v-if="image_url" class="image_url" :src="image_url">
            </b-card-img
            ><span class="span"></span
            ><b-icon-search v-if="image_url"></b-icon-search>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    image_url: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    price: {
      type: String,
      required: false,
      default: "0",
    },
  },

  methods: {
    showImage(image_url, title) {
      const h = this.$createElement;
      // More complex structure
      const messageVNode = h("div", { class: ["image"] }, [
        h("b-img", {
          props: {
            src: image_url,
            thumbnail: true,
            center: true,
            fluid: true,
          },
        }),
      ]);

      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        title: title,
        hideHeaderClose: false,
        buttonSize: "sm",
        centered: true,
        size: "sm",
      });
    },
  },
};
</script>

<style scoped>
.card-img-right {
  min-height: 100px;
  min-width: 100px;
  cursor: pointer;
}

.image_url {
  height: 126px;
  width: 94px;
  border-radius: 10px;
}

.image-col {
  padding: 0;
}

.card-body {
  /*   background: rgb(88, 73, 130); */
  /*  color: rgb(207, 89, 141); */
  color: rgb(207, 89, 141);
  font-size: small;
  text-align: justify;
}

.card-title {
  font-size: small !important;
  text-align: left;
  font-weight: bold;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.075);
}

.span {
  padding-left: 1px;
}
</style>
