<script>
export default {
  name: "Modal",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <transition name="modal-fade">
    <div class="mymodal-backdrop">
      <div
        class="mymodal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="mymodal-header" id="modalTitle">
          <slot name="header"> This is the default tile! </slot>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            x
          </button>
        </header>

        <section class="mymodal-body" id="modalDescription">
          <slot name="body"> This is the default body! </slot>
        </section>

        <footer class="mymodal-footer">
          <slot name="footer"> </slot>
          <button
            type="button"
            class="btn-green"
            @click="close"
            aria-label="Close modal"
          >
            Ok
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<style>
.mymodal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mymodal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.mymodal-header,
.mymodal-footer {
  padding: 15px;
  display: flex;
}

.mymodal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: rgb(88, 73, 130);
  justify-content: space-between;
}

.mymodal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
}

.mymodal-body {
  color: rgb(88, 73, 130);
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: rgb(88, 73, 130);
  background: transparent;
}

.btn-green {
  color: white;
  background: rgb(88, 73, 130);
  border: 1px solid rgb(88, 73, 130);
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 1s ease;
}
</style>
